<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="grey lighten-2 white--text"
      ></v-breadcrumbs>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            @click="$router.go(-1)"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >

          <div class="d-flex justify-end align-center mb-4">
            <v-chip
              class="mr-2"
              color="orange"
              v-if="solicitacao.statusCarta === 'Em aprovação'"
              >Aguardando aprovação</v-chip
            >
            <v-menu
              offset-y
              left
              v-if="
                solicitacao.carta &&
                checkStatus(['Finalizado'], solicitacao.status)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  depressed
                  color="fmq_gray"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Ações <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="abrirPDF()"
                  v-if="checkStatus(['Finalizado'], solicitacao.status)"
                >
                  <v-list-item-title>Ver PDF da Carta</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="dialogResposta = true"
                  v-if="checkStatus(['Finalizado'], solicitacao.status)"
                >
                  <v-list-item-title>Reenviar Resposta</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="dialogModelo = true"
                  v-if="
                    checkStatus(['Finalizado'], solicitacao.status) &&
                    cartaConverter
                  "
                >
                  <v-list-item-title
                    >Criar Modelo a partir da Carta</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-row>
          <v-col cols="8">
            <SolicitacoesResumo
              class="mb-8"
              :solicitacao="solicitacao"
              :listLinhas="listLinhas"
              :listMedicosSolicitantes="listMedicosSolicitantes"
            />
            <template v-if="solicitacao.carta && checkMesa">
              <SolicitacaoCartaResposta
                :solicitacao="solicitacao"
                :listTags="listTags"
                :listCategorias="listCategorias"
                :listArtigos="listArtigos"
                :listProdutos="listProdutos"
                :viewOnly="true"
                :artigos="solicitacao.carta.artigos"
              />
            </template>
          </v-col>
          <v-col cols="4">
            <SolicitacoesComentarios :comentarios="solicitacao.comentarios" />
          </v-col>
        </v-row>
      </div>
    </div>
    <AlertConfirmation
      :dialog="dialogResposta"
      dialogMessage="Tem certeza que deseja reenviar o email para o Médico Solicitante?"
      @close="dialogResposta = false"
      @accept="reeenviarResposta"
    />
    <AlertConfirmation
      :dialog="dialogModelo"
      dialogMessage="Tem certeza que deseja converter essa Carta Resposta em um Modelo?"
      @close="dialogModelo = false"
      @accept="converterEmModelo"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Ação concluída com sucesso"
      @close="
        exibir();
        success = false;
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import SolicitacaoCartaResposta from "@/components/solicitacoes/SolicitacaoCartaResposta.vue";
import SolicitacoesComentarios from "@/components/solicitacoes/SolicitacoesComentarios.vue";
import SolicitacoesResumo from "@/components/solicitacoes/SolicitacoesResumo.vue";
import {
  exibirSolicitacao,
  reenviarRespotaSolicitacao,
} from "@/services/solicitacoes";
import { listarLinha } from "@/services/linhas";
import { listarMedicoSolicitante } from "@/services/medico-solicitante";
import { listarTags } from "@/services/tags";
import { listarArtigos } from "@/services/artigos.js";
import { listarProdutos } from "@/services/produtos.js";
import { listarCategoria } from "@/services/categorias.js";
import { convertEmModeloCarta } from "@/services/carta.js";
import { UserTypes } from "@/utils/UserTypes.js";
export default {
  name: "SolicitacaoVisualizarAtendimento",
  components: {
    SolicitacoesComentarios,
    SolicitacoesResumo,
    SolicitacaoCartaResposta,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Solicitações",
        disabled: false,
        to: "/",
      },
      {
        text: "Visualizar Atendimento",
        disabled: true,
        to: "",
      },
    ],
    loading: false,
    solicitacao: {},
    error: false,
    success: false,
    messageError: null,
    listLinhas: [],
    listMedicosSolicitantes: [],
    listTags: [],
    listArtigos: [],
    listProdutos: [],
    listCategorias: [],
    dialogResposta: false,
    dialogModelo: false,
  }),
  created() {
    this.getProdutos();
    this.getArtigos();
    this.getCategorias();
    this.getTags();
    this.getLinhas();
    this.getMedicosSolicitantes();
    this.exibir();
  },
  methods: {
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirSolicitacao(this.$route.params.id);
        this.solicitacao = resp.data;
        if (this.solicitacao.mesasId === 2) {
          this.breadcrumbs[0].text = "Lista de Treinamentos";
          this.breadcrumbs[0].to = "/treinamento";
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async reeenviarResposta() {
      this.dialogResposta = false;
      this.error = false;
      try {
        const resp = await reenviarRespotaSolicitacao(this.$route.params.id);
        if (resp.status === 200) {
          this.success = true;
        }
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async converterEmModelo() {
      this.dialogModelo = false;
      this.error = false;
      try {
        const resp = await convertEmModeloCarta(this.solicitacao.carta.id);
        if (resp.status === 201) {
          this.$router.push({
            name: `BancoDeCartasICVisualizar`,
            params: { id: resp.data.id },
          });
        }
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async abrirPDF() {
      const hash = this.solicitacao.carta.hash;
      window.open(
        process.env.VUE_APP_API + "donwload/cartas/" + hash,
        "_blank"
      );
    },
    async getLinhas() {
      const resp = await listarLinha();
      this.listLinhas = resp.data;
    },
    async getMedicosSolicitantes() {
      const resp = await listarMedicoSolicitante();
      this.listMedicosSolicitantes = resp.data;
    },
    async getTags() {
      const resp = await listarTags();
      this.listTags = resp.data;
    },
    async getArtigos() {
      const resp = await listarArtigos();
      this.listArtigos = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getCategorias() {
      const resp = await listarCategoria();
      this.listCategorias = resp.data;
    },
    checkStatus(status, status_check) {
      return status.indexOf(status_check) >= 0;
    },
  },
  computed: {
    cartaConverter() {
      return this.$store.state.user.data.permissoes.includes(
        "carta.converterEmModelo"
      );
    },
    checkMesa() {
      /* console.log(UserTypes.UserWithMesa());
      console.log(this.$store.state.user.data.tipo);
      console.log(UserTypes.UserWithMesa().includes(this.$store.state.user.data.tipo));
      console.log(this.$store.state.user.data.funcionario.mesaId);
      console.log(this.solicitacao);
      console.log(this.solicitacao.mesasId);
      console.log(this.$store.state.user.data.funcionario.mesaId === this.solicitacao.mesasId); */
      if (
        this.$store.state.user.data.tipo === UserTypes.ADM_MASTER 
      ) {
        return true;
      }
      
      if (
        UserTypes.UserWithMesa().includes(this.$store.state.user.data.tipo)  && 
        this.$store.state.user.data.funcionario.mesaId === this.solicitacao.mesasId
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style></style>
